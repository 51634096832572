@import 'normalize.css';

// FONTS
@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto-Regular.ttf');
  font-weight: 100;
}
@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto-Medium.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto-Bold.ttf');
  font-weight: 600;
}

:root {
  //Default Layout
  --font-family: 'Roboto', sans-serif;

  --primaty: #e8b339;
}

html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizeSpeed;

  overflow-y: overlay;
  background-color: var(--colorBgSecond);
}

//////////////////
.flex-item {
  flex: 0 0 calc(50% - 10px);
  padding: 20px 0;
}

.border-right {
  border-right: 1px solid #dbdbdb;
}

.border-bottom {
  border-bottom: 1px solid #dbdbdb;
}

.padding-left {
  padding-left: 24px;
}

.oveview-block {
  text-align: center;
}

.icon {
  font-size: 40px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.info-wrapper {
  display: flex;
  p {
    min-width: 200px;
  }
}
.failed, .success, .pending{
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  width: 100px;
  border-radius: .8rem;
}
.failed{
  border-color: red !important;
  color: red !important;
  background-color: #a100001a;
}
.success{
  border-color: #00a186!important;
  color: #00a186!important;
  background-color: #00a1861a;
}
.pending{
  border-color: #b7cf06!important;
  color: #b7cf06!important;
  background-color: #bed70024;
}
